import React, { useState } from "react";
import { FaCloudUploadAlt, FaDownload } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { read, utils } from "xlsx";
import FullPageSimpleLoading from "../../CommonFiles/UI Elements/FullPageSimpleLoading";
import {
  filterObjectsByKeys,
  getFileTypeFromURL,
  isEqual,
} from "../../CommonFiles/Utils/ObjectUtils";
import "./QuestionUpload.css";

const AllColumns = [
  "Sl.No.",
  "Type",
  "Question",
  "MediaURL",
  "Choice 1",
  "Confirm Yes if Choice 1 is correct",
  "Choice 2",
  "Confirm Yes if Choice 2 is correct",
  "Choice 3",
  "Confirm Yes if Choice 3 is correct",
  "Choice 4",
  "Confirm Yes if Choice 4 is correct",
  "Choice 5",
  "Confirm Yes if Choice 5 is correct",
  "Subjective Answer",
  "Keyword 1",
  "Marks for Keyword 1",
  "Keyword 2",
  "Marks for Keyword 2",
  "Keyword 3",
  "Marks for Keyword 3",
  "Total Marks",
  "Negative Marks",
];

function QuestionUpload(props) {
  const [loading, setLoading] = useState(false);

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    const fileExtension = selectedFile?.name.split(".").pop().toLowerCase();
    const validExtensions = ["xlsx", "xls"];

    if (!selectedFile || !validExtensions.includes(fileExtension)) {
      toast.error("Please upload a valid .xlsx or .xls file", {
        position: "top-center",
      });
      return;
    }

    setLoading(true); // Start loading

    try {
      // Read the file
      const data = await selectedFile.arrayBuffer();
      const workbook = read(data);

      // Get the first worksheet
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      // Convert to JSON
      let result = utils
        .sheet_to_json(worksheet, {
          defval: null,
          blankrows: true,
        })
        .filter((row) => Object.values(row).some((value) => value !== null));

      if (!result || result.length === 0) {
        toast.error("No data found in the Excel file", {
          position: "top-center",
        });
        return;
      }

      result = filterObjectsByKeys(result, AllColumns);

      if (!isEqual(Object.keys(result[0]), AllColumns)) {
        toast.error("Invalid column names in the Excel file", {
          position: "top-center",
        });
        return;
      }

      const processedData = result.map((row) => {
        if (row["Type"] === "MCQ") {
          const choicesArray = Object.keys(row)
            .filter((key) => key.startsWith("Choice")) // Get only keys that start with "Choice"
            .map((key) => row[key]) // Extract corresponding values
            .filter((value) => value !== null && value.trim() !== ""); // Remove null or empty values

          const choices = choicesArray.map((choice, index) => ({
            id: index + 1,
            choice: choice,
            type: getFileTypeFromURL(choice) === "image" ? "image" : "text",
          }));

          const answers = getAnswers(row);

          const mediaType =
            row["MediaURL"] && row["MediaURL"] !== ""
              ? getFileTypeFromURL(row["MediaURL"])
              : null;

          return {
            questionType: "MCQ",
            questionText: `<p>${row["Question"]}</p>`,
            questionMediaUrls: mediaType
              ? [{ mediaType: mediaType, mediaUrl: row["MediaURL"] }]
              : [],
            mcqType: answers.length === 1 ? "Single Answer" : "Multiple Answer",
            choices: choices,
            answers: answers,
            overallMarks: isNaN(Number(row["Total Marks"]))
              ? 0
              : Number(row["Total Marks"]),
            negativeMarks: isNaN(Number(row["Negative Marks"]))
              ? 0
              : Number(row["Negative Marks"]),
            isDraft: true,
          };
        } else if (row["Type"] === "Subjective") {
          const keywords = processKeywords(row);

          const mediaType =
            row["MediaURL"] && row["MediaURL"] !== ""
              ? getFileTypeFromURL(row["MediaURL"])
              : null;

          return {
            questionType: "Subjective",
            questionText: `<p>${row["Question"]}</p>`,
            questionMediaUrls: mediaType
              ? [{ mediaType: mediaType, mediaUrl: row["MediaURL"] }]
              : [],
            answer: row["Subjective Answer"],
            keywords: keywords,
            markingScheme:
              keywords.length > 0 && keywords.some((kw) => kw.marks) ? 2 : 1,
            keywordsSettings: 1,
            overallMarks:
              keywords.length > 0 && keywords.some((kw) => kw.marks)
                ? keywords.reduce((acc, kw) => acc + kw.marks, 0)
                : isNaN(Number(row["Total Marks"]))
                  ? 0
                  : Number(row["Total Marks"]),
            negativeMarks: isNaN(Number(row["Negative Marks"]))
              ? 0
              : Number(row["Negative Marks"]),
            isDraft: true,
          };
        } else {
          return null;
        }
      });

      props.onExtractCompleted(processedData);
    } catch (error) {
      console.error("Error parsing Excel file:", error);
      toast.error("An error occurred while processing the file.", {
        position: "top-center",
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  function getAnswers(obj) {
    return Object.keys(obj)
      .filter(
        (key) =>
          key.startsWith("Confirm Yes if Choice") &&
          obj[key] &&
          obj[key].toLowerCase().trim() === "yes"
      )
      .map((key) => {
        const match = key.match(/\d+/); // Extract the number from the key
        return match ? parseInt(match[0], 10) : null; // Return the choice number
      })
      .filter((num) => num !== null); // Remove null values
  }

  function processKeywords(obj) {
    const result = [];

    Object.keys(obj).forEach((key) => {
      const match = key.match(/^Keyword (\d+)$/); // Match "Keyword X" pattern
      if (match && obj[key] !== null && obj[key].trim() !== "") {
        const id = parseInt(match[1], 10); // Extract the number from the key
        const keywordVariants = obj[key].split(",").map((str) => str.trim()); // Convert string to an array
        const marksKey = `Marks for Keyword ${id}`;
        const marks = Number.isFinite(obj[marksKey]) ? obj[marksKey] : null; // Ensure marks is a number or null

        result.push({ id, keywordVariants, marks });
      }
    });

    return result;
  }

  const handleDownloadSample = () => {
    const link = document.createElement("a");
    link.href =
      "https://gradimages.s3.ap-south-1.amazonaws.com/templates/Quiz_Upload_Template_GradPartners.xlsx";
    link.download = "Question-Bulk-Upload-Template.csv";
    link.click();
  };

  return (
    <div className="upload-container">
      {loading && (
        <FullPageSimpleLoading message="Working on it, please wait..." />
      )}
      <ToastContainer />
      <h2>Upload excel file for questions</h2>
      <div className="upload-box">
        <label htmlFor="file-upload" className="upload-label">
          <FaCloudUploadAlt className="upload-icon" />
          <span>Click to Upload .xlsx or .xls file</span>
          <p>Maximum file size is 10 MB</p>
          <input
            id="file-upload"
            type="file"
            accept=".xlsx, .xls"
            onChange={(e) => {
              handleFileChange(e);
              e.target.value = null;
            }}
            className="file-input"
          />
        </label>
      </div>

      <h3>Follow these steps to upload the .xlsx or .xls file-</h3>
      <ul className="instructions">
        <li>
          Download the Sample sheet and fill Questions and Solutions in
          respective columns.
        </li>
        <li>
          Use 'Subjective' for Subjective Questions and 'MCQ' for Multiple
          Choice Questions in 'Type' column.
        </li>
        <li>
          Please do not fill alphabets in 'Total Marks', 'Marks' for individual
          keywords, and 'Negative Marks' columns.
        </li>
        <li>
          Use ',' as separator for multiple keyword variants in the 'Keyword'
          columns.
        </li>
        <li>If there are no question media Urls, leave the column blank.</li>
        <li>
          Do not modify or rearrange the sheet headers (i.e., column names) to
          avoid confusion.
        </li>
        <li>Only .xlsx or .xls file format can be uploaded.</li>
        <li>
          Check all the questions one by one and save them after modifications
          (if any).
        </li>
      </ul>

      <button onClick={handleDownloadSample} className="download-btn">
        <FaDownload className="download-icon" /> Download Sample
      </button>
    </div>
  );
}

export default QuestionUpload;

import React from "react";
import { FileTypes } from "../../User/quizManagement/QuestionAuthor";
import { QUESTION_STATUS } from "./QuestionStatusEnum";

function QuizPlayerQuestionContent({
  currentQuestion,
  savingResponse,
  onAnswer,
  onSubjectiveAnswer,
  quizData,
}) {
  const currentQuestionJson = currentQuestion.question.questionJson;

  const isTimeUp =
    currentQuestion.timeLeft &&
    currentQuestion.timeLeft.minutes <= 0 &&
    currentQuestion.timeLeft.seconds <= 0;

  const isAnswerDisabled =
    savingResponse ||
    isTimeUp ||
    (!quizData.revisitAnswers &&
      (currentQuestion.status === QUESTION_STATUS.SKIPPED ||
        currentQuestion.status === QUESTION_STATUS.ANSWERED));

  return (
    <div className="question-content">
      <p className="question-text">
        <div
          dangerouslySetInnerHTML={{
            __html: currentQuestionJson.questionText,
          }}
        />
      </p>

      {currentQuestionJson.questionMediaUrls.filter((item) =>
        FileTypes.image.includes(item.mediaType)
      ).length > 0 && (
        <div className="question-media">
          {currentQuestionJson.questionMediaUrls
            .filter((item) => FileTypes.image.includes(item.mediaType))
            .map((item, index) => (
              <div key={index} className="question-media-item-image">
                <img
                  src={item.mediaUrl}
                  alt={`Question Media ${index + 1}`}
                  className="question-media-image"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
            ))}
        </div>
      )}

      {currentQuestionJson.questionMediaUrls.filter((item) =>
        FileTypes.audio.includes(item.mediaType)
      ).length > 0 && (
        <div className="question-media">
          {currentQuestionJson.questionMediaUrls
            .filter((item) => FileTypes.audio.includes(item.mediaType))
            .map((item, index) => (
              <div key={index} className="question-media-item-image">
                <audio
                  src={item.mediaUrl}
                  controls
                  controlsList="nodownload"
                  className="question-media-audio"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
            ))}
        </div>
      )}

      {currentQuestionJson.questionMediaUrls.filter((item) =>
        FileTypes.video.includes(item.mediaType)
      ).length > 0 && (
        <div className="question-media">
          {currentQuestionJson.questionMediaUrls
            .filter((item) => FileTypes.video.includes(item.mediaType))
            .map((item, index) => (
              <div key={index} className="question-media-item-image">
                <video
                  src={item.mediaUrl}
                  controls
                  controlsList="nodownload"
                  className="question-media-video"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
            ))}
        </div>
      )}

      {currentQuestionJson.questionType === "MCQ" && (
        <div className="answer-options">
          {currentQuestionJson.choices.map((answer, index) => (
            <label
              key={answer.id}
              className={`answer-option ${
                currentQuestion.response &&
                currentQuestion.response.answer.find((a) => a.id === answer.id)
                  ? "selected"
                  : ""
              }`}
            >
              <input
                type={
                  currentQuestionJson.mcqType === "Single Answer"
                    ? "radio"
                    : "checkbox"
                }
                name="answer"
                className="answer-checkbox"
                checked={
                  currentQuestion.response &&
                  currentQuestion.response.answer.find(
                    (a) => a.id === answer.id
                  )
                    ? true
                    : false
                }
                onChange={(e) => {
                  onAnswer(
                    answer,
                    currentQuestionJson.mcqType,
                    e.target.checked
                  );
                }}
                disabled={isAnswerDisabled}
              />
              <span>
                {answer.type === "image" ? (
                  <img
                    src={answer.choice}
                    alt={`Choice ${index + 1}`}
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  answer.choice
                )}
              </span>
            </label>
          ))}
        </div>
      )}

      {currentQuestionJson.questionType === "Subjective" && (
        <div className="answer-options">
          <textarea
            className="subjective-answer"
            placeholder="Type your answer here..."
            disabled={isAnswerDisabled}
            rows={5}
            value={currentQuestion.response?.answer || ""}
            onChange={(e) => onSubjectiveAnswer(e.target.value)}
          />
        </div>
      )}
    </div>
  );
}

export default QuizPlayerQuestionContent;

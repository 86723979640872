import { FileTypes } from "../../User/quizManagement/QuestionAuthor";

export const trimObjectValues = (obj) => {
  let cleanedObj = {};

  for (let key in obj) {
    if (obj.hasOwnProperty(key) && typeof obj[key] === "string") {
      cleanedObj[key] = obj[key].trim();
    } else {
      cleanedObj[key] = obj[key];
    }
  }

  return cleanedObj;
};

export function isEqual(value1, value2, visited = new WeakMap()) {
  // Handle identical references or primitive equality
  if (value1 === value2) return true;

  // Handle `null` or `undefined`
  if (value1 == null || value2 == null) return value1 === value2;

  // Handle different types
  if (typeof value1 !== typeof value2) return false;

  // Handle special object types
  if (value1 instanceof Date && value2 instanceof Date) {
    return value1.getTime() === value2.getTime();
  }

  if (value1 instanceof RegExp && value2 instanceof RegExp) {
    return value1.toString() === value2.toString();
  }

  if (value1 instanceof Map && value2 instanceof Map) {
    if (value1.size !== value2.size) return false;
    return Array.from(value1.entries()).every(([key, val]) =>
      isEqual(val, value2.get(key))
    );
  }

  if (value1 instanceof Set && value2 instanceof Set) {
    if (value1.size !== value2.size) return false;
    return Array.from(value1).every((item) => value2.has(item));
  }

  // Handle arrays
  if (Array.isArray(value1) && Array.isArray(value2)) {
    if (value1.length !== value2.length) return false;
    return value1.every((item, index) => isEqual(item, value2[index]));
  }

  // Handle objects
  if (typeof value1 === "object" && typeof value2 === "object") {
    // Prevent infinite recursion for circular references
    if (visited.has(value1)) return visited.get(value1) === value2;
    visited.set(value1, value2);

    const keys1 = Object.keys(value1);
    const keys2 = Object.keys(value2);

    if (keys1.length !== keys2.length) return false;

    return keys1.every((key) => isEqual(value1[key], value2[key], visited));
  }

  // Fallback for unhandled cases
  return false;
}

export function getYearLabel(year) {
  const suffixes = ["th", "st", "nd", "rd"];
  const remainder = year % 10;
  const suffix =
    remainder === 1 && year !== 11
      ? suffixes[1]
      : remainder === 2 && year !== 12
        ? suffixes[2]
        : remainder === 3 && year !== 13
          ? suffixes[3]
          : suffixes[0];
  return `${year}${suffix} Year`;
}

export function getTextFromHTML(htmlContent) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent;
  return tempDiv.innerText || tempDiv.textContent;
}

export function getFileTypeFromURL(url) {
  if (typeof url !== "string" || !isValidURL(url)) {
    return null; // Return null for invalid URLs
  }

  const extension = url
    .split(".")
    .pop()
    .split("?")[0]
    .split("#")[0]
    .toLowerCase();

  const mimeTypes = Object.values(FileTypes)
    .flat()
    .reduce((acc, mimeType) => {
      const ext = mimeType.split("/").pop(); // Get the extension
      acc[ext] = mimeType; // Store in object
      return acc;
    }, {});

  return mimeTypes[extension] || null;
}

// Helper function to check if a string is a valid URL
function isValidURL(str) {
  try {
    new URL(str);
    return true;
  } catch (error) {
    return false;
  }
}

export function filterObjectsByKeys(arr, keys) {
  return arr.map((obj) =>
    keys.reduce((acc, key) => {
      if (key in obj) acc[key] = obj[key]; // Keep only keys in the template
      return acc;
    }, {})
  );
}

export function getTimeObject(time) {
  const [hours, minutes, seconds] = time.split(":").map(Number);
  return { hours, minutes, seconds };
}

export function getTimeObjectShort(time) {
  const [minutes, seconds] = time.split(":").map(Number);
  return { minutes, seconds };
}

export function extractMinutesSeconds(time) {
  const parts = time.split(":"); // Split the string by ":"
  return `${parts[1]}:${parts[2]}`; // Return only minutes and seconds
}

export function isTotalTimeGreater(totalTime, timeLeft) {
  function toSeconds(time) {
    return (
      (time.hours || 0) * 3600 + (time.minutes || 0) * 60 + (time.seconds || 0)
    );
  }
  return toSeconds(totalTime) > toSeconds(timeLeft);
}

export function multiplyTime(duration, factor) {
  // Split the time string into hours, minutes, and seconds
  let [hours, minutes, seconds] = duration.split(":").map(Number);

  // Convert everything to total seconds
  let totalSeconds = hours * 3600 + minutes * 60 + seconds;

  // Multiply by the factor
  totalSeconds *= factor;

  // Convert back to hh:mm:ss
  let newHours = String(Math.floor(totalSeconds / 3600)).padStart(2, "0");
  let newMinutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(
    2,
    "0"
  );
  let newSeconds = String(totalSeconds % 60).padStart(2, "0");

  return `${newHours}:${newMinutes}:${newSeconds}`;
}

export function totalQuizTimeLeft(duration, startTime) {
  function toSeconds(time) {
    const parts = time.split(":").map(Number);
    return parts[0] * 3600 + parts[1] * 60 + (parts[2] || 0);
  }

  // Convert duration to seconds
  let totalDuration = toSeconds(duration);

  // Get the difference between now and the start time in seconds
  let startTimestamp = new Date(startTime).getTime();
  let currentTimestamp = Date.now();
  let elapsed = Math.floor((currentTimestamp - startTimestamp) / 1000);

  // Calculate remaining time
  let remaining = Math.max(totalDuration - elapsed, 0);

  // Convert seconds back to hh:mm:ss format
  let hours = String(Math.floor(remaining / 3600)).padStart(2, "0");
  let minutes = String(Math.floor((remaining % 3600) / 60)).padStart(2, "0");
  let seconds = String(remaining % 60).padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
}

export function calculateTimeSpent(previouslySpent, startTime) {
  function toSeconds(time) {
    const parts = time.split(":").map(Number);
    return parts[0] * 60 + parts[1];
  }

  function toTimeFormat(seconds) {
    let minutes = String(Math.floor(seconds / 60)).padStart(2, "0");
    let secs = String(seconds % 60).padStart(2, "0");
    return `${minutes}:${secs}`;
  }

  // Convert previously spent time to seconds
  let totalSpent = toSeconds(previouslySpent);

  // Get the difference between now and the start time in seconds
  let startTimestamp = new Date(startTime).getTime();
  let currentTimestamp = Date.now();
  let elapsed = Math.floor((currentTimestamp - startTimestamp) / 1000);

  // Calculate total time spent
  let newTotalSpent = totalSpent + elapsed;

  return toTimeFormat(newTotalSpent);
}

export function getIndividualTimeLeft(timePerQuestion, timeSpent) {
  function toSeconds(time) {
    const parts = time.split(":").map(Number);
    return parts[0] * 60 + parts[1];
  }

  function toTimeFormat(seconds) {
    let minutes = String(Math.floor(seconds / 60)).padStart(2, "0");
    let secs = String(seconds % 60).padStart(2, "0");
    return `${minutes}:${secs}`;
  }

  // Convert inputs to seconds
  let totalTime = toSeconds(timePerQuestion);
  let spentTime = toSeconds(timeSpent);

  // Calculate remaining time
  let remainingTime = Math.max(totalTime - spentTime, 0);

  return toTimeFormat(remainingTime);
}

// Timer.jsx
import "./Timer.css";

const Timer = (props) => {
  const progress = (props.timeLeft / props.timePerQuestions) * 100;

  return (
    <div className="timer-wrapper">
      <div
        className="timer-container"
        style={{
          "--progress": `${progress}%`,
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Timer;

import React, { useState } from "react";
import "./Modal.css";

const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  className = "",
  width = "500px",
  titleAtCenter = false,
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className={`modal-container ${className}`} style={{ width: width }}>
        {title && (
          <div
            className="modal-header"
            style={{
              justifyContent:
                titleAtCenter && !onClose ? "center" : "space-between",
            }}
          >
            <h2>{title}</h2>
            {onClose && (
              <button
                onClick={onClose}
                className="modal-close-btn"
                aria-label="Close modal"
              >
                &times;
              </button>
            )}
          </div>
        )}
        <div className="custom-modal-content">
          {!title && onClose && (
            <button
              onClick={onClose}
              className="modal-close-btn"
              aria-label="Close modal"
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            >
              &times;
            </button>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

// Custom hook for modal state management
export const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return {
    isModalOpen,
    openModal,
    closeModal,
  };
};

export default Modal;

import React from "react";
import logo from "../../../images/main_logo.png";

function QuizPlayerHeader({ opportunityData, quizTimeLeft }) {
  const formatNumber = (num) => String(num).padStart(2, "0");

  return (
    <header className="header">
      <img src={logo} alt="Grad Partners Logo" className="gp-logo" />

      <div className="header-left">
        <div className="opportunity-wrapper">
          <img
            src={opportunityData.logo_image}
            alt={opportunityData.organization}
            className="opportunity-logo"
          />
          <span className="header-title">{opportunityData.title}</span>
        </div>
      </div>
      <div className="header-right">
        <span className="time-left-text">
          Time Left:{" "}
          <div className="timer-blocks">
            <span className="timer-block">
              {formatNumber(quizTimeLeft.hours)}
            </span>

            <span className="separator">:</span>

            <span className="timer-block">
              {formatNumber(quizTimeLeft.minutes)}
            </span>

            <span className="separator">:</span>

            <span className="timer-block">
              {formatNumber(quizTimeLeft.seconds)}
            </span>
          </div>
        </span>
        <button className="finish-button">Finish Quiz</button>
      </div>
    </header>
  );
}

export default QuizPlayerHeader;

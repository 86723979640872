import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import logo from "../../../images/main_logo.png";
import config from "../../CommonFiles/config.json";
import { multiplyTime } from "../../CommonFiles/Utils/ObjectUtils";
import "./QuizHomePage.css";
import QuizPlayer from "./QuizPlayer";

const USER_TOKEN = localStorage.getItem("token");
const mainURL = Object.values(config["URL"]).join("");

const QuizHomePage = () => {
  const history = useHistory();
  const { slug, roundId } = useParams();
  const [confirmChecked, setConfirmChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [opportunityData, setOpportunityData] = useState(null);
  const [roundData, setRoundData] = useState(null);
  const [quizData, setQuizData] = useState(null);
  const [assessmentUser, setAssessmentUser] = useState(null);
  const [quizStarted, setQuizStarted] = useState(false);

  useEffect(() => {
    if (slug && roundId && !isNaN(Number(roundId)) && USER_TOKEN) {
      const AuthStr = "Bearer ".concat(USER_TOKEN);
      setIsLoading(true);
      axios({
        method: "get",
        url: `${mainURL}/opportunity/${slug}`,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          setOpportunityData(response.data.data);

          const round = response.data.rounds.find(
            (r) => r.id === Number(roundId)
          );

          if (round) {
            setRoundData(round);

            if (round.quiz_setting) {
              setQuizData(JSON.parse(round.quiz_setting));
            } else {
              onDataError();
            }
          } else {
            onDataError();
          }

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          onDataError();
        });
    } else {
      history.push(`/e/${slug}`);
    }
    // eslint-disable-next-line
  }, []);

  const onDataError = (redirect = true, message) => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: `${message || "Something went wrong. Please try again."}`,
      confirmButtonText: "OK",
      showCancelButton: false,
    }).then((result) => {
      if (result.isConfirmed) {
        redirect && history.push(`/e/${slug}`);
      }
    });
  };

  const formatDateTime = (date, time) => {
    // Combine date and time
    const dateTime = `${date} ${time}`;

    // Format the date
    return moment(dateTime, "YYYY-MM-DD HH:mm").format("Do MMM'YY h:mm A");
  };

  const formatDuration = (time) => {
    const duration = moment.duration(time);

    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${hours > 0 ? `${hours} hr${hours > 1 ? "s" : ""} ` : ""} ${minutes > 0 ? `${minutes} min${minutes > 1 ? "s" : ""} ` : ""} ${seconds > 0 ? `${seconds} sec${seconds > 1 ? "s" : ""}` : ""}`;
  };

  const onStartQuiz = () => {
    if (confirmChecked) {
      if (USER_TOKEN) {
        const AuthStr = "Bearer ".concat(USER_TOKEN);
        axios({
          method: "post",
          url: `${mainURL}/assessment/start`,
          data: { opportunityId: opportunityData.id, roundId: roundData.id },
          headers: { Authorization: AuthStr },
        })
          .then((response) => {
            if (response.data.status === 0) {
              onDataError(false, response.data.message);
            } else {
              setAssessmentUser(response.data.data);
              setQuizStarted(true);
            }
          })
          .catch((err) => {
            if (!err.response?.data?.auth) {
              onDataError(
                true,
                "You are not authorized to start the quiz. Please login to continue."
              );
            } else {
              onDataError(false);
            }
          });
      }
    }
  };

  const onFullScreenExit = () => {
    setQuizStarted(false);
    setConfirmChecked(false);
    Swal.fire({
      icon: "warning",
      title: "Violation",
      text: "You can't exit the full screen mode while the quiz is in progress. This is counted as a violation of the rules. Repetition of this violation will result in disqualification.",
      confirmButtonText: "OK",
    });
  };

  if (!quizStarted) {
    return (
      <div className="quiz-container">
        <div className="quiz-card">
          {isLoading ? (
            <div className="quiz-home-loader-container">
              <Loader type="Bars" color="#ee3835" height={100} width={100} />
            </div>
          ) : (
            opportunityData &&
            roundData &&
            quizData && (
              <>
                <div className="quiz-sections">
                  {/* Left Section */}
                  <div className="left-section">
                    <div className="company-header">
                      <div className="logo-container">
                        <img
                          src={opportunityData.logo_image}
                          alt={opportunityData.organization}
                          className="company-logo"
                        />
                      </div>
                      <div className="company-info">
                        <h1>{opportunityData.title}</h1>
                        <p>{opportunityData.organization}</p>
                      </div>
                    </div>

                    <div className="quiz-info">
                      <h2>{roundData.title}</h2>

                      <div className="quiz-details">
                        <div className="detail-row">
                          <span className="label">Start Date & Time:</span>
                          <span className="value">
                            {formatDateTime(
                              roundData.start_date,
                              roundData.start_time
                            )}
                          </span>
                        </div>
                        <div className="detail-row">
                          <span className="label">End Date & Time:</span>
                          <span className="value">
                            {formatDateTime(
                              roundData.end_date,
                              roundData.end_time
                            )}
                          </span>
                        </div>
                        <div className="detail-row">
                          <span className="label">Quiz Duration:</span>
                          <span className="value">
                            {formatDuration(
                              multiplyTime(
                                quizData.timePerQuestion,
                                quizData.questionIds.length
                              )
                            )}
                          </span>
                        </div>
                        <div className="detail-row">
                          <span className="label">Total Questions:</span>
                          <span className="value">
                            {quizData.questionIds.length}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Right Section */}
                  <div className="right-section">
                    <span className="right-section-header">
                      {/* This text is getting set by CSS */}
                      <h2>{quizData.assessmentTitle}</h2>
                      <img src={logo} alt="logo" className="gp-logo-home" />
                    </span>
                    <hr />

                    <ul className="instructions-list">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: quizData.assessmentInstructions,
                        }}
                      ></div>
                    </ul>
                    <span className="quiz-instructions-checkbox">
                      <input
                        type="checkbox"
                        onChange={(e) => setConfirmChecked(e.target.checked)}
                        checked={confirmChecked}
                      />
                      <p
                        onClick={() => setConfirmChecked(!confirmChecked)}
                        style={{ marginBottom: "unset", cursor: "pointer" }}
                      >
                        I confirm that I have read all the instructions and
                        guidelines carefully and ready to start the assessment.
                      </p>
                    </span>
                  </div>
                </div>
                <button
                  className="start-quiz-btn"
                  onClick={onStartQuiz}
                  disabled={!confirmChecked}
                >
                  Start Quiz
                </button>
              </>
            )
          )}
        </div>
      </div>
    );
  } else {
    if (!assessmentUser) {
      return (
        <div>
          {onDataError(true, "Some error occurred while starting the quiz")}
        </div>
      );
    } else {
      return (
        <QuizPlayer
          assessmentUser={assessmentUser}
          quizData={quizData}
          opportunityData={opportunityData}
          onFullScreenExit={onFullScreenExit}
        />
      );
    }
  }
};

export default QuizHomePage;

import React from "react";
import Modal from "../../CommonFiles/UI Elements/Modal/Modal";

function QuizTimesUpModal() {
  return (
    <Modal
      isOpen={true}
      title="Time’s Up! ⏳"
      width="400px"
      titleAtCenter={true}
    >
      <span
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p style={{ textAlign: "center", fontSize: "16px" }}>
          That was a great effort! Your quiz time has ended. Let’s wrap things
          up by submitting your answers.
        </p>
        <button className="finish-button">Finish Quiz</button>
      </span>
    </Modal>
  );
}

export default QuizTimesUpModal;
